<template>
 
<svg class="icon" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M9.75 0.786133C9.19771 0.786133 8.75 1.23385 8.75 1.78613V4.78613C8.75 5.33842 9.19771 5.78613 9.75 5.78613C10.3023 5.78613 10.75 5.33842 10.75 4.78613V2.78613H16.75V16.7861H10.75V14.7861C10.75 14.2338 10.3023 13.7861 9.75 13.7861C9.19771 13.7861 8.75 14.2338 8.75 14.7861V17.7861C8.75 18.3384 9.19771 18.7861 9.75 18.7861H17.75C18.3023 18.7861 18.75 18.3384 18.75 17.7861V1.78613C18.75 1.23385 18.3023 0.786133 17.75 0.786133H9.75ZM4.04289 6.07903C4.43342 5.6885 5.06658 5.6885 5.45711 6.07903C5.84763 6.46955 5.84763 7.10272 5.45711 7.49324L4.16421 8.78613H13.75C14.3023 8.78613 14.75 9.23385 14.75 9.78613C14.75 10.3384 14.3023 10.7861 13.75 10.7861H4.16421L5.45711 12.079C5.84763 12.4696 5.84763 13.1027 5.45711 13.4932C5.06658 13.8838 4.43342 13.8838 4.04289 13.4932L1.04289 10.4932C0.652369 10.1027 0.652369 9.46955 1.04289 9.07903L4.04289 6.07903Z" />
</svg>

</template>
<script>
//import interceptorsSetup from './configs/axios.js';
export default {
    name: "icn-exit",
}
</script>

