<template>
    <div class="file-upload-widget">
      <DragModule @added="throwUp"></DragModule>
      <MenuModule @added="throwUp"></MenuModule>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
 
import DragModule from './UploadWidgetDrag.vue';
  import MenuModule from './UploadWidgetMobile.vue';
  export default {
    name: 'files-upload-widget',
    components: {
      DragModule,MenuModule
    },
    data:function(){
        return {
            loading_progress:0,
            loading_state:false,

        }
    },
    computed:{
      getLoadingProgress(){
        return this.loading_progress;
       // return this.$store.getters.getTestValue;
      },
      getLoadingState(){
        return this.loading_state;
       // return this.$store.getters.getTestValue;
      }
    },
    
    methods:{
      throwUp(files){
        this.$emit('added',files);
      },
       
    }
  }
  </script>
  