<template>
    <div class="ui-form-element select">
      <label v-if="getLabel!=null" :for="getUID">{{ getLabel }}</label>

      <div class="select" @mouseover="showVarians" @mouseleave="hideVarians">
        <div class="item">{{ getValue }}</div>
        <div class="variants" v-if="getShhowVariants">
          <div v-for="item in getVariants" :class="(item==getValue)?'item selected':'item'" :key="item" @click="upEvent(item)">{{ item }}</div>
        </div>
      </div>
       

    </div>
</template>
  
<script>
  // @ is an alias to /src
  //import HelloWorld from '../views/HelloWorld.vue'
  export default {
    name: 'ui-form-select',
    components: {
     // HelloWorld
    },
    // eslint-disable-next-line
    props: {// eslint-disable-next-line
        value:'',// eslint-disable-next-line
        label:'',// eslint-disable-next-line
        placeholder:'',
        variants:[],
            /* user: 
            {
                type: Object,
                default: ()=>{return   {
                   name:null,
                    phone:null,
                    email:null,
                    bdate:null,
                    gender:null 
                };}
            }, */
      },
    data: function () {
        return {
            form_uid:'none',
            show_variants:false,
        }
        
    },
    mounted() {
       this.form_uid = this.$stringHelpers.uuidv4();
    },
    computed:{
      getUID(){
        return this.form_uid;
      },
      getValue(){
        if(this.value=='') {return this.placeholder;}
        return this.value;
      },
      getLabel(){
        return this.label;
      },
      getVariants(){
        return this.variants;
      },
      getShhowVariants(){
        return this.show_variants;
      }
    },
    methods:{
      showVarians(){
        this.show_variants=true;
      },
      hideVarians(){
        this.show_variants=false;
      },
      upEvent(val){
        this.hideVarians();
        let e ={
          target:{
            value:val,
          }
        }
        console.log(e);
        this.$emit('change',e);
      },
      
    }
  }
  </script>
  