<template>
    <div :class="{active:getGlobalPanelSettings.slidable}" >
        <nav  :class="{[getFlexOrient]:true,'global-side-menu':true} ">




            <!-- <router-link :to="{ name: 'user', params: { username: 'erina' }}">Home</router-link> | -->
            <div :class="{[getFlexOrient]:true}" class="menu-top">

                <div class="link-top" :class="{hidden:getFlexOrient==='row'}">
                    <div class="icon-cont" @click="holdPanelSlide">
                    <icn-main-menu></icn-main-menu>
                    </div>
                </div>
               

                <router-link :to="{name:'events'}" class="link-top">
                    <div class="icon-cont">
                        <icn-history></icn-history>
                    </div>
                    
                    <nobr>{{  $t("events") }}</nobr>
                </router-link>
                
                <router-link :to="{name:'users'}" class="link-top">
                    <div class="icon-cont">
                        <IcnUserControll></IcnUserControll>
                    </div>
                    
                    <nobr>{{  $t("controll") }}</nobr>
                </router-link>


                
                <router-link :to="{name:'team'}" class="link-top">
                    <div class="icon-cont">
                        <icn-team></icn-team>
                    </div>
                    
                    <nobr>{{  $t("team") }}</nobr>
                </router-link>

            </div>
            <div :class="{[getFlexOrient]:true}" class="menu-bottom">
                <!-- <div class="switch-box" :class="{hidden:getFlexOrient==='row'}">
                    <div class="icon-cont">
                        <ui-form-switch @change="holdPanelSlide" :state="getGlobalPanelSettings.slidable"></ui-form-switch>
                    </div>
                    <nobr>авто выдвижение</nobr>
                </div> -->
                <a @click="$emit('logout')" class="link-top">
                    <div class="icon-cont">
                        <icn-exit></icn-exit>
                    </div>
                    {{ $t("exit") }}
                </a>
            </div>
           

            
            <!--  <router-link :to="{ name: 'about' }">About</router-link> |
            <router-link :to="{ name: 'login' }">Login</router-link> |
            <router-link :to="{ name: 'testauthjwt' }">Test JWT</router-link> -->
        </nav>
    </div>
</template>

<script>
//import axios from 'axios';
import api from "../../../configs/api";
import IcnExit from "../../ui/icons/actions/IcnExit.vue";
import IcnHistory from "../../ui/icons/apps/IcnHistory.vue";
import IcnTeam from "../../ui/icons/apps/IcnTeam.vue";
import IcnMainMenu from "../../ui/icons/MainMenu.vue";
import IcnUserControll from "../../ui/icons/apps/IcnUserControll.vue";
export default {
    name: "global-side-menu",
    components: {
        IcnExit,IcnHistory,IcnTeam,IcnMainMenu,IcnUserControll
    },
    data: function () {
        return {
            login: "",
            orient:'row'
        };
    },
    
    i18n: {
        // `i18n` option, setup locale info for component
        messages2: {
            ru: { login: "Вход2" },
            en: { login: "Login" },
        },
    },
    computed: {
        getFlexOrient(){ //это какашечно
            return this.orient
        },
        getUser() {
            return this.$store.getters.getUser;
        },
        getGlobalPanelSettings(){
            return this.$store.getters.getGlobalPanelSettings;
        }
    },
    mounted() {
        const user = this.getUser;
        console.log("MY USER");
        console.log(user);
        this.$attrs.class.split(' ').forEach(element => {
            if(element==='column') this.orient=element;
            else if(element==='row') this.orient=element;
        });
        console.error();
    },
    methods: {
        holdPanelSlide(){
            console.log('holdPanelSlide');
            const settings = {...this.getGlobalPanelSettings}
            settings.slidable=!settings.slidable;
            this.$store.commit("setGlobalPanelSettings", settings);
        },

        setUserLogin(e) {
            let user = this.getUser;
            console.log("MY USER>>>");
            console.log(user);
            console.log(e.target.value);
            user.login = e.target.value;
            this.$store.commit("setUser", user);
        },
        reqAuthCode() {
            console.log("reqAuthCode");
            var url = api.auth.code.get;

            this.$axios
                .post(url, {
                    login: this.getUser.login,
                })
                .then((response) => {
                    console.log(response.data);
                    // this.updateShowMess(response.data.message);
                    // this.showForm=false;
                    if (response.data.errorCode == 0) {
                        // this.updateShowMess(this.$t('ok'));
                        //response.data.data.token;
                        //response.data.user.email;
                        //response.data.user.phone;
                    } else {
                        //console.log('error');
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../../../../../../sass/apps/admin/sys/navi/global_side_menu.scss';
</style>
