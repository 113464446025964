<template>
    <div   class="timer-countdown">
         {{ getTimerCount }} {{ $t('sec') }}
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  //import HelloWorld from '../views/HelloWorld.vue'
  export default {
    name: 'sys-countdown-timer',
    data:function() {
        return {
            timerCount:5
        }
    },
    props: {
        time: {
        type: Number,
        default: 5
        },
    },
    created() {
        console.log(this.time);
        this.timerCount=parseInt(this.time);
        console.log(this.timerCount);
    },
    watch: {

        timerCount: {
            
            handler(value) {
                console.log('value'+value);
                if (value > 0) {
                    setTimeout(() => {
                         
                        this.setTimerCount(this.timerCount-1);
                    }, 1000);
                }

            },
           // immediate: true // This ensures the watcher is triggered upon creation
        }

    },
    computed:{
      getTimerCount(){
        return this.timerCount;
      }
    },
    methods:{
        setTimerCount(vl)
        {
         this.timerCount=vl;
      },
    },
  }
  </script>