<template>
    <div   class="sys-notification-list  cont">
        <div class="list  ">
           
            <div   v-for="item in getNotifyActiveList" :key="item.id" >
                <div  :class="'message cont '+item.type " v-if="item.state===1">
                    <div class="panel  " >  
                    <div class="title"><sapn>#{{ item.code }} </sapn>{{ item.title }}</div>
                    <button @click="deleteItem(item)" class="close">X</button>
                    </div>
                    <div class="body" v-html="item.body">
                     
                    </div>
                    
                    
                    <div class="panel bottom" v-if="(typeof item.self_distruct !== 'undefined')" >
                       
                        Closed in <sys-countdown-timer class="right" :time="item.self_distruct" ></sys-countdown-timer> 
                    </div>
                </div>
                
            </div>
        </div>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  //import HelloWorld from '../views/HelloWorld.vue'
  export default {
    name: 'sys-notification-list',
    components: {
     // HelloWorld
    },
    computed:{
      getNotifyActiveList(){
        return this.$store.getters.getNotifyList;
      }
    },
    methods:{
        deleteItem(ditem)
        {
        const newList =this.getNotifyActiveList.map((item)=>{
            if(item.id===ditem.id)item.state=0;
            return  item;
         });
        this.$store.commit('setNotifyList',newList);
      },
    }
  }
  </script>

 