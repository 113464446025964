/* eslint-disable */
export const string_helpers = {
  capitalizeFirstLetter_Mixin: str => str.charAt(0).toUpperCase() + str.slice(1),
  isValidEmail_Mixin:(email) => { 
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zА-яA-Z\-0-9]+\.)+[a-zА-яA-Z]{2,}))$/; 
    return re.test(String(email).toLowerCase()); 
  },
  isValidRuPhone_Mixin:(phone) => { 
    let phoned=phone.match(/\d{11}/g);
    if(phoned!=null)return true; 
    return false; 
  },
  stringContainAnyOFArrayElements_Mixin(val,arr) {
    let result=false;
    arr.forEach(element => {
      if(val!=null)
      {
        if(!result && val.toString().includes(element)) {result=true;}
      } 
     
    });
    
    return result;
  },
  grepEmail_Mixin:(str) => { 
    let result=[];
    let serachArr=str.split(' ');
    serachArr.forEach(element => {
      let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zА-яA-Z\-0-9]+\.)+[a-zА-яA-Z]{2,}))$/; 
      let res= re.test(String(element).toLowerCase()); 
      if(res && !result.includes(element.toString())) result.push(element.toString());
    });
    return result; 
  },
  grepRuPhone_Mixin:(str) => { 
    let result=[];
    let serachArr=str.split(' ');
    serachArr.forEach(element => {
      let phoned=element.match(/\d{11}/g);
      if(phoned!=null)
      {
        if(phoned.toString().charAt(0)==8) phoned='7'+phoned.toString().slice(1)
        if(phoned.toString().charAt(0)==7 && !result.includes(phoned.toString())) result.push(phoned.toString());
      }
      
    });
    return result; 
  },
  isValidDate_Mixin(date) { 
    if(date==null) return false;
    let res=date.match(/\d{1,2}([\/.-])\d{1,2}\1\d{4}/g)
    if(res!=null) return true;
    return false;
  },
  grepDates_Mixin:(str) => {
    let result=[];
    let serachArr=str.split(' ');
    
    serachArr.forEach(element => {
      let date=element.match(/\d{1,2}([\/.-])\d{1,2}\1\d{4}/g);
      if(date!=null)
      {
        if(!result.includes(date.toString())) result.push(date.toString());
      }
      
    });
    return result; 
  },
  grepMacsAddresses_Mixin(str) { 
    let result=[];
    let serachArr=str.split(' ');
    
    serachArr.forEach(element => {
      element=element.toString().toLowerCase();
      let res=element.match(/[0-9abcdef]{2}([:])[0-9abcdef]{2}\1[0-9abcdef]{2}\1[0-9abcdef]{2}\1[0-9abcdef]{2}\1[0-9abcdef]{2}/g)
    
      if(res!=null)
      {
        if(!result.includes(res.toString())) result.push(res.toString());
      }
      
    });
    return result;
  },
  isValidMacAddress_Mixin(date) { 
    if(date==null) return false;
    date=date.toString().toLowerCase();
    
    let res=date.match(/[0-9abcdef]{2}([:])[0-9abcdef]{2}\1[0-9abcdef]{2}\1[0-9abcdef]{2}\1[0-9abcdef]{2}\1[0-9abcdef]{2}/g)
    if(res!=null) return true;
    return false;
  },
  img2xUrl_Mixin(val) {
    return `${val.replace(/(\.[\w\d_-]+)$/i, '@2x$1')} 2x`;
  },
   uuidv4() {
      return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
      );
    },
  testImgUrl(url){ //не работает
    
      var http = new XMLHttpRequest();
      http.withCredentials = true;
      http.open('GET', url, false);
      http.send();
      if (http.status != 404)
          return true;
      else
          return false;
   

  } , 
adaptDateTime(date){
  const parts = date.split(' ');
  return (parts[0]+"T"+parts[1]).slice(0, -3); 
} , 
adaptDate(date){
  const parts = date.split(' ');
  return parts[0]; 
} ,  
transliterate(word){
  const a = {"-":"_"," ":"_","Ё":"YO","Й":"I","Ц":"TS","У":"U","К":"K","Е":"E","Н":"N","Г":"G","Ш":"SH","Щ":"SCH","З":"Z","Х":"H","Ъ":"'","ё":"yo","й":"i","ц":"ts","у":"u","к":"k","е":"e","н":"n","г":"g","ш":"sh","щ":"sch","з":"z","х":"h","ъ":"'","Ф":"F","Ы":"I","В":"V","А":"A","П":"P","Р":"R","О":"O","Л":"L","Д":"D","Ж":"ZH","Э":"E","ф":"f","ы":"i","в":"v","а":"a","п":"p","р":"r","о":"o","л":"l","д":"d","ж":"zh","э":"e","Я":"Ya","Ч":"CH","С":"S","М":"M","И":"I","Т":"T","Ь":"'","Б":"B","Ю":"YU","я":"ya","ч":"ch","с":"s","м":"m","и":"i","т":"t","ь":"'","б":"b","ю":"yu"};

return word.split('').map(function (char) { 
  return a[char] || char; 
}).join("");
}
}