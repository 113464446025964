/* import axios from 'axios';
import { store } from './store/store'

console.log(JSON.parse(localStorage.getItem('store')).user.phone);
export const HTTP = axios.create({
    baseURL: 'http://sms.done/',
    headers:{
        Authorization: 'Bearer '+btoa(JSON.parse(localStorage.getItem('store')).user.token),
        UserPhone: JSON.parse(localStorage.getItem('store')).user.phone
    }
}) */

//import axios from 'axios';
//import router from "../routers/router";
import { store } from "../stores/store";
function extractHostname(url) {
    var hostname;
    //find & remove protocol (http, ftp, etc.) and get hostname

    if (url.indexOf("//") > -1) {
        hostname = url.split("/")[2];
    } else {
        hostname = url.split("/")[0];
    }

    //find & remove port number
    hostname = hostname.split(":")[0];
    //find & remove "?"
    hostname = hostname.split("?")[0];

    return hostname;
}

export default function setup(axios) {
    axios.interceptors.request.use(
        function (config) {
            store.commit('setIsBusy',true);
            //console.log("Authorization: setup");
            let tokens = store.getters.getSysTokens;
            //const email = store.getters.getUserEmail;
            //console.log("Authorization:"+btoa(token));
            //console.log(["tokens", tokens]);
           // console.log(tokens);
           // console.log(config.url);
            //console.warn('interceptor ON');
            let domain = extractHostname(config.url);
            //console.warn('url::'+config.url);
            //console.warn('domain::'+domain);
            //  let authState = store.getters.getSysAuth;
            //  if(authState)
            //   {
            // console.error("domain::" + domain);
            let result = tokens.filter((data) => {
                //console.log(data);
                //console.log(data.data);
                //console.log(data.data.domain + "==" + domain);
               // console.log(data.data.domain == domain);
                return data.data.domain == domain;
            });
            //console.warn(tokens);
            //console.error(result);
            if (typeof result[0] == "undefined")
                result = tokens.filter((data) => data.data.domain == "");
            if (typeof result[0] == "undefined")
                result = tokens.filter(
                    (data) => typeof data.data.domain == "undefined"
                );
            //const result = tokens;
            //console.log(result);
            if (typeof result[0] != "undefined") {
                //if(result[0].base64!=null)
                // {
                //console.log("setup headers");
                //console.log(result[0].data);
                //console.log(result[0].signature);
                config.headers.AuthData = JSON.stringify(result[0].data);
                config.headers.AuthSig = result[0].signature;
                // }
                // else
                // {
                //     config.headers.Authorization = result[0].signature;
                //  }
                //`Bearer ${token}`;
            } else {
                console.log("no tokens");
            }
            //console.log('Authorization:');
            //console.log(config.headers.Authorization);
            /*  if(token) {
                config.headers.Authorization = btoa(token);//`Bearer ${token}`;
            }
            if(login) {
                config.headers.login = `${login}`;
            } */

            /*  if(email)
            {
                config.headers.useremail = `${email}`;
            } */

            //      return config;
            //   }

            // if(domain!='') throw 'error 72';
            return config;
        },
        function (err) {
            //console.log(err);
            return Promise.reject(err);
        }
    );

    // response parse
    axios.interceptors.response.use(
        async (response) => {
            ////console.warn('response interseptor');
            ////console.warn(response);
            //console.warn(response);
            store.commit('setIsBusy',false);
            if (response.data.errCode == 72) {
                //const tokens2 = store.getters.getSysTokens;
                //console.log("!retry req after refresh");
                //console.log(tokens2);
                return await store
                    .dispatch("refreshTokens")
                    .then((response2) => {
                        // console.warn("refreshTokens CALL!!");
                        // console.warn(response2.data);
                        // console.warn(response2.data.errCode);
                        if (response2.data.errCode == 0) {
                            return axios.request(response.config);
                        }
                        return response;
                    });
                //return   axios.request(response.config);
            } else if (response.data.errCode == 73) {
                //console.log("!72");
                //console.error('Auth 72');
                store.dispatch("cleanSysToken");
//                router.push("/");
                return response;
                //window.history.back();
            } else {
                return response;
            }

            //parseBody(response)
        },
        (error) => {
            //console.warn('Error status', error.response.status)
            // return Promise.reject(error)
            if (error.response) {
                return error; //parseError(error.response.data)
            } else {
                return Promise.reject(error);
            }
        }
    );
}
