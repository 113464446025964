<template>
     <div class="switch">

      <label v-if="getLabel!=null" :for="getUID" >{{getLabel}}</label>
     <div @click="swichState" :class="{on:getState}" class="holder" :name="getUID" :id="getUID">
         <div class="roller"></div>
     </div>

        
         
    </div>
</template>
  
<script>
  // @ is an alias to /src
  //import HelloWorld from '../views/HelloWorld.vue'
  export default {
    name: 'ui-form-switch-digit',
    components: {
     // HelloWorld
    },
    // eslint-disable-next-line
    props: {// eslint-disable-next-line
        value:'',// eslint-disable-next-line
        label:null,// eslint-disable-next-line
        placeholder:'',
        state:{
            type:Boolean,
            default:false
        },
            /* user: 
            {
                type: Object,
                default: ()=>{return   {
                   name:null,
                    phone:null,
                    email:null,
                    bdate:null,
                    gender:null 
                };}
            }, */
      },
    data: function () {
        return {
            form_uid:'none'
        }
        
    },
    mounted() {
       this.form_uid = this.$stringHelpers.uuidv4();
    },
    computed:{
      getState()
       {
           return this.state==1;
       }, 
      getUID(){
        return this.form_uid;
      },
      getValue(){
        return this.value;
      },
      getLabel(){
        return this.label;
      },
      getPlaceholder(){
        return this.placeholder;
      }
    },
    methods:{
      swichState()
      {
        let new_state=0;
        (this.state==0)? new_state=1 : new_state=0;
        console.log('swichState');
       
        const e ={
          target:{
          value:new_state
          }
        };
        this.$emit('change',e);

      },
      setTestValue(e)
      {
        console.log(e.target.value);
        this.$store.commit('setTestValue',e.target.value);
      },
    }
  }
  </script>
  