<template>
  <div class="home">
    <sys-login   />
  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from '../views/HelloWorld.vue'
export default {
  name: 'AuthLoginPage',
  components: {
   // HelloWorld
  },
  computed:{
    getTest(){
      return this.$store.getters.getTestValue;
    }
  },
  methods:{
    setTestValue(e)
    {
      console.log(e.target.value);
      this.$store.commit('setTestValue',e.target.value);
    },
  }
}
</script>
