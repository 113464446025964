<template>
<svg class="icon" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill="#7979D955" d="M16 16L8.4 10.3C8.27651 10.2062 8.17623 10.0853 8.10687 9.94656C8.03752 9.80786 8.00096 9.65507 8 9.5V5C8 4.73478 8.10536 4.48043 8.29289 4.29289C8.48043 4.10536 8.73478 4 9 4H23C23.2652 4 23.5196 4.10536 23.7071 4.29289C23.8946 4.48043 24 4.73478 24 5V9.45C23.999 9.60507 23.9625 9.75786 23.8931 9.89656C23.8238 10.0353 23.7235 10.1562 23.6 10.25L16 16Z"   stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path fill="#7979D955" d="M16 16L8.4 21.7C8.27651 21.7938 8.17623 21.9147 8.10687 22.0534C8.03752 22.1921 8.00096 22.3449 8 22.5V27C8 27.2652 8.10536 27.5196 8.29289 27.7071C8.48043 27.8946 8.73478 28 9 28H23C23.2652 28 23.5196 27.8946 23.7071 27.7071C23.8946 27.5196 24 27.2652 24 27V22.55C23.999 22.3949 23.9625 22.2421 23.8931 22.1034C23.8238 21.9647 23.7235 21.8438 23.6 21.75L16 16Z"   stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M8 8H24"   stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</template>
<script>
//import interceptorsSetup from './configs/axios.js';
export default {
    name: "icn-exit",
}
</script>

