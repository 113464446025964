<template>
     <div class="ui-form-element">
        <label :for="getUID">{{ getLabel }}</label>
        <textarea type="text" @input="upEvent($event)" :name="getUID" :id="getUID" :getPlaceholder="getPlaceholder" :value="getValue"></textarea>
    </div>
</template>
  
<script>
  // @ is an alias to /src
  //import HelloWorld from '../views/HelloWorld.vue'
  export default {
    name: 'ui-form-radio',
    components: {
     // HelloWorld
    },
    // eslint-disable-next-line
    props: {// eslint-disable-next-line
        value:'',// eslint-disable-next-line
        label:'',// eslint-disable-next-line
        placeholder:''
            /* user: 
            {
                type: Object,
                default: ()=>{return   {
                   name:null,
                    phone:null,
                    email:null,
                    bdate:null,
                    gender:null 
                };}
            }, */
      },
    data: function () {
        return {
            form_uid:'none'
        }
        
    },
    mounted() {
       this.form_uid = this.$stringHelpers.uuidv4();
    },
    computed:{
      getUID(){
        return this.form_uid;
      },
      getValue(){
        return this.value;
      },
      getLabel(){
        return this.label;
      },
      getPlaceholder(){
        return this.placeholder;
      }
    },
    methods:{
      upEvent(e){
        console.log(e);
        this.$emit('change',e);
      },
      setTestValue(e)
      {
        console.log(e.target.value);
        this.$store.commit('setTestValue',e.target.value);
      },
    }
  }
  </script>
  