import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../components/pages/HomeView.vue'
import AuthLoginPage from '../components/pages/AuthLoginPage.vue'
 

//import SysLogin from "./components/sys/auth/Login.vue";
import AuthByLinkPage from "../components/sys/auth/AuthByLink.vue";


import AuthTestJWTPage from '../components/pages/AuthTestJWTPage.vue'
import NotFound from '../components/pages/NotFoundPage.vue'

//import { store } from "../stores/store";
const hackPublicPath=''; // /adm-controll
const routes = [
  {
    path: hackPublicPath+'/',
   
    name: 'home',
    component:HomeView,
     
    
  },
  {
    path: hackPublicPath+'/about',
     
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/pages/AboutView.vue'),
     
  },
  {
    path: hackPublicPath+'/users',
     
    name: 'users-root',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component:  () => import(/* webpackChunkName: "sys_users" */ '../components/pages/Users/UsersRoot.vue'),
     
    children: [
      {
        // UserProfile will be rendered inside User's <router-view>
        // when /user/:id/profile is matched
        path: '',
        name:'users',
        component:  () => import(/* webpackChunkName: "sys_users" */ '../components/pages/Users/UserList.vue'),
     
      },
      {
        // UserProfile will be rendered inside User's <router-view>
        // when /user/:id/profile is matched
        path: 'list',
        name:'users-list',
        component:  () => import(/* webpackChunkName: "sys_users" */ '../components/pages/Users/UserList.vue'),
     
      },
      {
        // UserProfile will be rendered inside User's <router-view>
        // when /user/:id/profile is matched
        path: 'create',
        name:'users-create',
        component:  () => import(/* webpackChunkName: "sys_users" */ '../components/pages/Users/UserCreate.vue'),
     
      },
      {
        // UserProfile will be rendered inside User's <router-view>
        // when /user/:id/profile is matched
        path: 'edit/:id',
        name:'users-edit',
        component:  () => import(/* webpackChunkName: "sys_users" */ '../components/pages/Users/UserEdit.vue'),
     
      },
    ]
  },
  {
    path: hackPublicPath+'/events',
     
    name: 'events-root',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component:  () => import(/* webpackChunkName: "events_simple" */ '../components/pages/Events/EventsRoot.vue'),
     
    children: [
      {
        // UserProfile will be rendered inside User's <router-view>
        // when /user/:id/profile is matched
        path: '',
        name:'events',
        component:  () => import(/* webpackChunkName: "events_simple_root" */ '../components/pages/Events/EventsList.vue'),
     
      },
      {
        // UserProfile will be rendered inside User's <router-view>
        // when /user/:id/profile is matched
        path: 'list',
        name:'events-list',
        component:  () => import(/* webpackChunkName: "events_simple_list" */ '../components/pages/Events/EventsList.vue'),
     
      },
      {
        // UserProfile will be rendered inside User's <router-view>
        // when /user/:id/profile is matched
        path: 'create',
        name:'events-create',
        component:  () => import(/* webpackChunkName: "events_simple_create" */ '../components/pages/Events/EventsCreate.vue'),
     
      },
      {
        // UserProfile will be rendered inside User's <router-view>
        // when /user/:id/profile is matched
        path: 'edit/:id',
        name:'events-edit',
        component:  () => import(/* webpackChunkName: "events_simple_edit" */ '../components/pages/Events/EventsEdit.vue'),
     
      },
    ]
  },
  {
    path: hackPublicPath+'/team',
     
    name: 'team-root',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component:  () => import(/* webpackChunkName: "team_simple" */ '../components/pages/Team/TeamRoot.vue'),
     
    children: [
      {
        // UserProfile will be rendered inside User's <router-view>
        // when /user/:id/profile is matched
        path: '',
        name:'team',
        component:  () => import(/* webpackChunkName: "team_simple" */ '../components/pages/Team/TeamList.vue'),
     
      },
      {
        // UserProfile will be rendered inside User's <router-view>
        // when /user/:id/profile is matched
        path: 'list',
        name:'team-list',
        component:  () => import(/* webpackChunkName: "team_simple" */ '../components/pages/Team/TeamList.vue'),
     
      },
      {
        // UserProfile will be rendered inside User's <router-view>
        // when /user/:id/profile is matched
        path: 'create',
        name:'team-create',
        component:  () => import(/* webpackChunkName: "team_simple" */ '../components/pages/Team/TeamCreate.vue'),
     
      },
      {
        // UserProfile will be rendered inside User's <router-view>
        // when /user/:id/profile is matched
        path: 'edit/:id',
        name:'team-edit',
        component:  () => import(/* webpackChunkName: "team_simple" */ '../components/pages/Team/TeamEdit.vue'),
     
      },
    ]
  },
  {
    
    path: hackPublicPath+'/login',
    name: 'login',
    components: {
      default:AuthLoginPage,
      login: AuthLoginPage 
    },
    meta: {
      hideForAuth: true
    }
  },
  {
    
    path: hackPublicPath+'/auth',
    name: 'authbylink',
    components: {
      default:AuthByLinkPage,
      login: AuthByLinkPage 
    },
    
    meta: {
      hideForAuth: true
    }
  },
  { 
    
    path: hackPublicPath+'/test/auth/jwt',
    name: 'testauthjwt',
    /* component: AuthTestJWTPageб */
    components: {
      default:AuthTestJWTPage,
      
    },
  },
   
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    
    components: {
      default:NotFound,
      login: AuthLoginPage 
    },
  }
]

const router = createRouter({
  
  history: createWebHistory(process.env.MIX_BASE_URL),
  
  routes
})


// не подходит из-за JWT
/* router.beforeEach((to, from, next) => {

  console.log(to.meta.hideForAuth)
  if (typeof to.meta.hideForAuth === 'undefined'){
     
    if (store.getters.getSysAuth===true) {
      next();
    }
    else
    {
      next({ name: 'login' });
    }
  }else{
    next();
    if (store.getters.getSysAuth===true) {
      next({ name: 'home' });
    }
    else
    {
      next();
    }
  }
 
}); */

export default router
