<template>
    <div class="string-list">
        <div class="list">
                <div v-for="item in getStrings" :key="$stringHelpers.transliterate(item)" class="item"> 
                  
                   
                  <p>{{item}}</p>
                  <button @click="removeString(item)">X</button>
                   </div>
            </div>
           
             
            <ui-form-input-txt @change="setNewString" :required="true" :label="getLabel" :value="getNewString"></ui-form-input-txt>
               
            <button @click="addNewString">{{ $t('add') }}</button>
             
    </div>
  </template>
  <style lang="scss" scoped>
    .string-list{
      .list{
        .item{
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }
    }
  </style>
  <script>
  // @ is an alias to /src
 
  //import HelloWorld from '../views/HelloWorld.vue'
  export default {
    name: 'string-array-manager',
    components: {
     // HelloWorld
    },
    props: {
    strings: {
      type: Array,
      default: ()=>[]
    },
    label: {
      type: String,
      default: 'Новая строка'
    },
    
  },
    data:function(){
        return {
            loading_progress:0,
            loading_state:false,
            new_string:''
        }
    },
    computed:{
      getStrings(){
        return this.strings;
      },
      getNewString(){
        return this.new_string;
      },
      getLabel(){
        return this.label;
       // return this.$store.getters.getTestValue;
      }
    },
    
    methods:{
        setNewString(e){
            this.new_string=e.target.value;
        },

        addNewString(){
            if(this.getNewString!=='' && this.getNewString!==null && typeof  this.getNewString!=='undefined'){

                const fruits = this.getStrings;
                if(fruits.includes(this.getNewString)){
                    //TODO::  такая строка уже есть
                }else{
                    const fruits2=[...fruits,this.getNewString]
                    this.$emit('newArray',fruits2);
                    this.new_string='';
                }
                
                
            }

             
        },
        removeString(word){
            console.log('removeString: '+word);
            const fruits = this.getStrings;
            const newFruits=fruits.filter((item)=>{return item!=word});
            this.$emit('newArray',newFruits);
        }
      
    }
  }
  </script>
  