<template>
  <div class="home">
   <h1>Test JWT</h1>
   <p>{{getMessage}}</p>
  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from '../views/HelloWorld.vue'
import api from '../../configs/api';
export default {
  name: 'AuthTestJWTPage',
  components: {
   // HelloWorld
  },
  data: function() {
    return {
      message:'start test'
    }
  },
  i18n: { // `i18n` option, setup locale info for component
    messages: {
      ru:  {  ok:'Токен установлен',fail:'Ошибка неверный код',progress:'Проверка кода...' } ,
      en:  { ok:'Token ok',fail:'Wrong_code',progress:'Code cheking...'  } ,
    }
   },
  mounted() {
    var url=api.auth.jwt.check;
          
          this.$axios.get(url,{
                   
                })
                .then(response => {
                    console.log(response.data);
                   // this.updateShowMess(response.data.message);
                   // this.showForm=false;
                    if(response.data.errCode==0)
                    {      
                      // this.updateShowMess(this.$t('ok'));       
                      //response.data.data.token;
                      //response.data.user.email;  
                      //response.data.user.phone;
                      this.message=this.$t('ok')
                     // this.$store.dispatch('tokensUpdate',response.data.data.tokens)
                    }
                    else if(response.data.errCode==73) 
                    {
                      this.message=this.$t('fail')
                    }
                })
                .catch(error => {
                    console.log(error);
                    this.message=this.$t('fail')
                })
  },
  computed:{
    getMessage(){
      return this.message;
    }
  },
  methods:{
    setTestValue(e)
    {
      console.log(e.target.value);
      this.$store.commit('setTestValue',e.target.value);
    },
  }
}
</script>
