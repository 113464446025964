<template>
    <div class="auth-by-link">
        <h1>Авторизация по ссылке</h1>
        <p>{{ getMessage }}</p>
    </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from '../views/HelloWorld.vue'
import api from "../../../configs/api";
export default {
    name: "auth-by-link",
    components: {
        // HelloWorld
    },
    data: function () {
        return {
            message: "",
            
        };
    },
    i18n: {
        // `i18n` option, setup locale info for component
        messages: {
            ru: {
                ok: "Токен установлен",
                fail: "Ошибка неверный код",
                progress: "Проверка кода...",
            },
            en: {
                ok: "Token ok",
                fail: "Wrong_code",
                progress: "Code cheking...",
            },
        },
    },
    mounted() {
        this.message = this.$t("progress");
        let login = this.$route.query.login;
        let code = this.$route.query.code;
        console.log(login);
        console.log(code);
        this.authCodeCheck(login, code);
    },
    computed: {
        getMessage() {
            return this.message;
        },
    },
    methods: {
        authCodeCheck(login, code) {
            var url = api.auth.code.check;
            console.log(["login", login]);
            console.log(["code", code]);
            this.$axios
                .post(url, {
                    login: login,
                    code: code,
                })
                .then((response) => {
                    console.log(response.data);
                    // this.updateShowMess(response.data.message);
                    // this.showForm=false;
                    if (response.data.errCode == 0) {
                        // this.updateShowMess(this.$t('ok'));
                        //response.data.data.token;
                        //response.data.user.email;
                        //response.data.user.phone;
                        this.message = this.$t("ok");
                        this.$store.dispatch(
                            "tokensUpdate",
                            response.data.data.tokens
                        );
                       
                    } else if (response.data.errCode == 73) {
                        this.message = this.$t("fail");
                    } else if (response.data.errCode == 74) {
                        this.message = this.$t("fail"); //expair code
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    },
};
</script>
