<template>
    <div class="v-sys-login panel column">
        <h3>{{ $t("login") }}</h3>
        <form class="row flex-bottom" v-if="getMessage===null && getStatus===null " v-on:submit.prevent="reqAuthCode">
            <ui-form-input-txt @change="setUserLogin" :label="'Login'" :value="getUser.login" ></ui-form-input-txt>
       
            <button type="submit">запросить код</button>
        </form>
        <div v-else-if="getStatus!==null" class="status-message">
            {{ getStatus }}
        </div>
        <div v-else  class="message">
            {{ getMessage }}
            <button class="close" @click="closeMessage">X</button>
        </div>

    </div>
</template>

<script>
//import axios from 'axios';
import api from "../../../configs/api";
export default {
    name: "sys-login",
    data: function () {
        return {
            login: "",
            status:null,
            message:null
        };
    },
    i18n: {
        // `i18n` option, setup locale info for component
        messages: {


            ru: { 
                err_0: "Код отправлен",
                err_55: "Слишком много попыток" 
            },
            en: { 
                err_0: "Code was sent to you",
                err_55: "Too many attempts" 
            },
        },
    },
    computed: {
        getMessage(){
            return this.message;
        },  
        getStatus(){
            return this.status;
        }, 
        getUser() {
            return this.$store.getters.getUser;
        },
    },
    mounted() {
        const user = this.getUser;
        console.log("MY USER");
        console.log(user);
    },
    methods: {
        removeStatus(){this.status=null;},
        closeMessage(){
            this.message=null;
        },
        setUserLogin(e) {
            let user = this.getUser;
            console.log("MY USER>>>");
            console.log(user);
            console.log(e.target.value);
            user.login = e.target.value;
            this.$store.commit("setUser", user);
        },
        reqAuthCode() {
            console.log("reqAuthCode");
            var url = api.auth.code.get;
            this.closeMessage();
            this.status=this.$t('loading_progress');
            this.$axios
                .post(url, {
                    login: this.getUser.login,
                })
                .then((response) => {
                    console.log(response.data);
                    // this.updateShowMess(response.data.message);
                    // this.showForm=false;
                    this.removeStatus();
                    if(typeof response.data.errCode !=='undefined'){
                        console.log("("+response.data.errCode+")");
                        this.message=this.$t('err_'+response.data.errCode);
                        if (response.data.errCode == 0) {
                            
                            // this.updateShowMess(this.$t('ok'));
                            //response.data.data.token;
                            //response.data.user.email;
                            //response.data.user.phone;
                        } else {
                            //console.log('error');
                        }
                    }else
                    {
                        this.message=this.$t('err_connect');
                    }
                    
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
