<template>
<svg class="my-icon" width="17" height="18" viewBox="0 0 17 18"   xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M7.17556 6.59449C8.78921 6.59449 10.0973 5.28638 10.0973 3.67274C10.0973 2.05909 8.78921 0.750977 7.17556 0.750977C5.56192 0.750977 4.2538 2.05909 4.2538 3.67274C4.2538 5.28638 5.56192 6.59449 7.17556 6.59449ZM7.17562 15.5602L8.73971 14.6571C8.69291 14.4139 8.66841 14.1627 8.66841 13.9058C8.66841 11.7771 10.3509 10.0413 12.4587 9.95585C11.165 8.6557 9.27752 7.83645 7.17562 7.83645C4.21025 7.83645 1.67174 9.46707 0.625 11.7782L7.17562 15.5602ZM12.6217 17.3119C14.5028 17.3119 16.0278 15.787 16.0278 13.9058C16.0278 12.0247 14.5028 10.4998 12.6217 10.4998C10.7406 10.4998 9.21563 12.0247 9.21563 13.9058C9.21563 15.787 10.7406 17.3119 12.6217 17.3119ZM12.6217 16.7342C14.1838 16.7342 15.4501 15.4679 15.4501 13.9058C15.4501 12.3438 14.1838 11.0775 12.6217 11.0775C11.0597 11.0775 9.7934 12.3438 9.7934 13.9058C9.7934 15.4679 11.0597 16.7342 12.6217 16.7342ZM12.1559 11.7712H13.0877L13.3094 12.1658V12.2092C13.4673 12.2754 13.6136 12.3638 13.7445 12.4704L14.2375 12.4351L14.7034 13.242L14.4189 13.6622L14.3924 13.6775C14.3979 13.7344 14.4007 13.7921 14.4007 13.8504C14.4007 13.9444 14.3934 14.0367 14.3793 14.1268L14.4189 14.1496L14.7034 14.5698L14.2375 15.3767L13.7314 15.3404L13.6569 15.2974C13.5494 15.3745 13.4329 15.4398 13.3094 15.4917V15.5841L13.0877 16.0406H12.1559L11.9343 15.5841V15.4918C11.8107 15.44 11.6941 15.3746 11.5865 15.2975L11.4585 15.3713L11.006 15.3767L10.5401 14.5698L10.771 14.1805L10.8639 14.1269C10.8499 14.0368 10.8426 13.9445 10.8426 13.8504C10.8426 13.792 10.8454 13.7343 10.8509 13.6774L10.771 13.6313L10.5401 13.2421L11.006 12.4351L11.4586 12.4405L11.5036 12.4665C11.6334 12.3615 11.7782 12.2745 11.9343 12.209V12.1658L12.1559 11.7712ZM12.6217 15.3139C13.3994 15.3139 14.0299 14.6835 14.0299 13.9058C14.0299 13.128 13.3994 12.4976 12.6217 12.4976C11.844 12.4976 11.2135 13.128 11.2135 13.9058C11.2135 14.6835 11.844 15.3139 12.6217 15.3139Z" />
</svg>
</template>
<script>
//import interceptorsSetup from './configs/axios.js';
export default {
    name: "icn-user-controll",
}
</script>

