
    <template>
  
        <svg class="icon" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.244141 1.40039C0.244141 0.847656 0.691406 0.400391 1.24414 0.400391H15.9551C16.5078 0.400391 16.9551 0.847656 16.9551 1.40039V2.0625C16.9551 2.61523 16.5078 3.0625 15.9551 3.0625H1.24414C0.691406 3.0625 0.244141 2.61523 0.244141 2.0625V1.40039Z" />
    <path d="M0.244141 6.41406C0.244141 5.86133 0.691406 5.41406 1.24414 5.41406H9.02344C9.57617 5.41406 10.0234 5.86133 10.0234 6.41406V7.07617C10.0234 7.62891 9.57617 8.07617 9.02344 8.07617H1.24414C1.03906 8.07617 0.849609 8.01562 0.691406 7.91016C0.421875 7.73047 0.244141 7.42383 0.244141 7.07617V6.41406Z"  />
    <path d="M1.24414 10.4277C0.691406 10.4277 0.244141 10.875 0.244141 11.4277V12.0898C0.244141 12.4395 0.423828 12.7461 0.693359 12.9258C0.851562 13.0293 1.04102 13.0898 1.24414 13.0898H5.12305C5.67578 13.0898 6.12305 12.6426 6.12305 12.0898V11.4277C6.12305 11.1016 5.9668 10.8125 5.72656 10.6289C5.55859 10.502 5.34961 10.4277 5.12305 10.4277H1.24414Z"  />
    </svg>
        </template>
        <script>
        //import interceptorsSetup from './configs/axios.js';
        export default {
            name: "icn-main-menu",
        }
        </script>
        
        