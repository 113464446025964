export const authDomain = "http://fast-q.devv/api/v2";
export default {
    auth: {
        refresh: authDomain + "/auth/refresh",
        code: {
            get: authDomain + "/auth/code/get",
            check: authDomain + "/auth/code/check",
        },
        jwt: {
            check: authDomain + "/auth/jwt/check",
        },
        exit: authDomain + "/auth/exit",
    },
    admin:{
        roles:{
            getUserRoles:authDomain + "/admin/roles/getuserroles",
            setUserRole:authDomain + "/admin/roles/setuserrole",
            removeUserRole:authDomain + "/admin/roles/removeuserrole",
           
        },
        users:{
            getall:authDomain + "/admin/users/getall",
            update:authDomain + "/admin/users/update",
            create:authDomain + "/admin/users/create",
            getById:authDomain + "/admin/users/getbyid",
            contacts:{
                 getall:authDomain + "/admin/users/contacts/getall",
                 create:authDomain + "/admin/users/contacts/create",
                 update:authDomain + "/admin/users/contacts/update",
            }
        },
        
    },
    events:{
        get:authDomain + "/events/get",
        getall:authDomain + "/events/getall",
        getById:authDomain + "/events/getbyid",
        update:authDomain + "/events/update",
        create:authDomain + "/events/create",
    },
    team:{
        get:authDomain + "/team/get",
        getall:authDomain + "/team/getall",
        getById:authDomain + "/team/getbyid",
        update:authDomain + "/team/update",
        create:authDomain + "/team/create",
        
        searchByNameOrTitle:authDomain + "/team/search/name/title",
    },
    files:{
        upload:authDomain + "/files/upload",
        no_img_icn:authDomain + "/assets/img/no_img.png",
    }
};
