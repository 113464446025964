<template>
    <div class="file-upload-widget">
       <input v-if="!getLoadingState" ref="fileupload" type="file" @change="handleUpload" multiple>
       <!-- <p v-if="!getLoadingState">Выберите файлы</p> -->
       <p v-else>Загрузка... {{ getLoadingProgress }}% ожидайте.</p>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
import api from '../../../configs/api';
  //import HelloWorld from '../views/HelloWorld.vue'
  export default {
    name: 'files-upload-widget',
    components: {
     // HelloWorld
    },
    data:function(){
        return {
            loading_progress:0,
            loading_state:false,

        }
    },
    computed:{
      getLoadingProgress(){
        return this.loading_progress;
       // return this.$store.getters.getTestValue;
      },
      getLoadingState(){
        return this.loading_state;
       // return this.$store.getters.getTestValue;
      }
    },
    
    methods:{
      handleUpload(e) {
        console.log(e.target.files);
        this.loading_progress=0;
        this.loading_state=true;
        const files= [...e.target.files];
        console.log(files);
        const url = api.files.upload;
        console.log(url);
        const formData = new FormData();
        files.forEach(file => {
            console.log(file);
            formData.append("files[]", file);
        });
           
        this.$axios.post(url, formData,{
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                onUploadProgress: progressEvent => this.setUploadProgress(progressEvent)
            }).then((response) => {
                this.loading_state=false;
              
                console.log(response);
                 
                if (response.data.errCode === 0) {
                    console.log("Data ok!");
                   this.$emit('added',response.data.data.files);
                   this.$refs.fileupload.value = null;
                }
                response;
            });


      },
        setUploadProgress(progressEvent){
            this.loading_progress=Math.round(progressEvent.progress*100);
            //if(progressEvent.progress==1) this.loading_state=false;
        console.log(progressEvent)
    },
      
    }
  }
  </script>
  