<template>
    
    
  <!--   <div class="debug-info">
        <h1>TEST INFO</h1>
        <div> AuTH: {{ isAuth }}<br>Route: {{ $route.name }} <br>Tokens: {{ getSysTokens }}</div>
    </div> -->
        <div class="global-area">
            <div class="g_body flex-centered">
                <test-main :test="getTest"></test-main>
            </div>
           
        </div>
        
          
       <!--  <router-view  v-if="isAuth===true" name="default" class="global-area" /> -->
        <!-- <router-view  v-else name="login" class="global-area" /> -->
        <!-- мест для абсолютно позиционированных компонентов с отдельным стейтом -->
     
        <sys-notification-list></sys-notification-list>
        <div v-if="getIsBusy" class="global-loading"><!--  {{$t('loading_progress')}}  --></div>
</template>
<script>
//import interceptorsSetup from './configs/axios.js';
export default {
    name: "index-page",
    beforeCreate() {
        this.$store.dispatch("initialiseStore");
    },
    computed: {
        isAuth() {
            return this.$store.getters.getSysAuth;
        },
        /* getSysTokens(){
            return this.$store.getters.getSysTokens;
        }, */
        getIsBusy(){
            return this.$store.getters.getIsBusy;
        },
        getTest(){
            return {
                        id:1,
                        name:'ПУЭ: правила устройства электроустановок',
                        txt:'Подготовка к экзамену он-лайн',
                        type_id:1,              // 1 - последовательный        // 2 - дерево
                        user_id:1,              // составитель
                        
                        score_fin_min:0,        // 0 - очков до успешного завершения (все) или цифра
                        score_fin_type:0,       // 0 - не принудительно        // 1 - принудительно
                        score_type:0,           // 0 - правильный ответ 1 бал  // 1 - кастумные баллы

                        steps:0,                // - всего вопросов
                        steps_min_fin:0,        // - всего вопросов до успешного завершения (0 - все)
                        steps_min_state:0,      // 0 - не принудительно        // 1 - принудительно
                        steps_random_state:0,   // 0 - не рандомно             // 1 - рандомно

                        time_fin_sec:0,         // 0 - безлимтное время        // 1 - секунд
                        time_fin_state:0,       // 0 - не принудительно        // 1 - принудительно
                        
                        st_score_avg:0,         // 0 - не принудительно
                        st_score_min:0,         // 0 - не принудительно
                        st_score_max:0,         // 0 - не принудительно
                        st_count_fin:0,         // 0 - не принудительно
                        st_count_start:0,       // 0 - не принудительно
                        st_time_avg:0,          // 0 - не принудительно
                        st_time_max:0,          // 0 - не принудительно
                        st_time_min:0,          // 0 - не принудительно

                        state_publish:1,        // 1 - доступен
                        state_public:1,         // 0 - только по ссылке
                        state_no_reg:1,         // 1 - можно проходить без регистрации
                        state_anonymous:1,      // 1 - не отправлеят user_id

                        created_at:null,
                        delated_at:null,
                        finish_at:null,
                    }
        }
    },
    methods:{
        logout(){
            console.log('global logout');
            this.$store.dispatch("dropRefreshToken");
            

        },
        testMe(){
            console.log('est');
        }
    },
    mounted() {
        // interceptorsSetup();
        //  var locale=document.querySelector('html').getAttribute('lang');
        //  this.$i18n.locale=locale;
        
    },
    errorCaptured(err, vm, info) {
        console.log("errorCaptured");
        // this.$store.dispatch('cleanLocalStorage')
        console.log(err);
        console.log(vm);
        console.log(info);
    },
};
</script>
<style lang="scss">
@import "@../../../../../sass/apps/admin/app.scss";
</style>
