<template>
    <div class="test-main">
       <div v-if="!getLoadingState"   >
         Название: {{ getTestData.name }}
         <div>Описание: {{ getTestData.txt }} </div>

         <div class="navi block">
            <button v-if="getResultData.created_at==null" @click="startTest">начать</button>
         </div>

         <div class="test-body">
            <div v-if="getResultData.created_at!=null" class="navi right">
                <div class="timer" :time="getResultData.created_at" >00:00:00</div>
                <div class="step"> 0 / {{ getTestData.steps }} </div>
                <div class="variant ok">  {{ getMyOk }} </div>
                <div class="variant fail">  {{ getMyFail }} </div>
            </div>
            <div v-if="getCurrentQuestion!=null" class="block">
                ВОПРОС # {{ getCurrentQuestion.id }}
                <div class="name">
                    {{ getCurrentQuestion.name }}
                </div>

                <div class="variants block">
                    <div v-for="answer in getCurrentQuestion.answers" :key="answer.id"  
                    class="block variant"
                    :class="{ 
                      'ok':getCurrentQuestion.answer_id!==null && getCurrentQuestion.answer_id==answer.id,
                      'fail':getCurrentQuestion.answer_id!==null && getCurrentQuestion.answer_id!=answer.id}">
                     #{{ answer.id }}   {{ answer.value }}

                     <div class="navi right">
                      <button   @click="selectAnswer(answer)">ВЫБРАТЬ  </button>
                     </div>
                     
       
                    </div>
                </div>

            </div>
            
            <button v-if="getMyAnswer!==null"   @click="goNext(getCurrentQuestion)"> СЛЕДУЩИЙ </button>
            <button v-if="getCurrentQuestion!=null && getCurrentQuestion.id>0"    @click="getQuestion(1)"> СНАЧАЛА </button>
         </div>
         
       </div>
       <p v-else>Загрузка... {{ getLoadingProgress }}% ожидайте.</p>
    </div>
</template>
<style scoped >
.variant.ok{
  color:green;
}
.variant.fail{
  color:red;
}
</style>
<script>
  // @ is an alias to /src
//import api from '../../../configs/api';
  //import HelloWorld from '../views/HelloWorld.vue'
  export default {
    name: 'test-main',
    components: {
     // HelloWorld
    },
    props: {
        test: {
            type: Object,
            default: ()=>null
        },
    
    
    },
    data:function(){
        return {
            my_answer:null,
            my_ok:0,
            my_error:0,
            test_current_question:null,
            test_result:{
                id:1,
                test_id:1,
                
                stat_no_reg:0,          // 1 - рег        // 0 - не было реги
                user_id:0,              // проходил
                
                score_fin:0,               // набрал очков
                steps_fin:0,               // ответил вопрос
                time_fin:null,             // рельное время прохождения

                questions:[],
                
                created_at:null,
                finish_at:null,
            },
            loading_progress:0,
            loading_state:false,
        }
    },
    computed:{
      getMyOk(){
        return this.my_ok;
      },
      getMyFail(){
        return this.my_error;
      },
      getMyAnswer(){
        return this.my_answer;
      },


      getTestData(){
        return this.test;
      },
      getResultData(){
        return this.test_result;
      },
      getCurrentQuestion(){
        return this.test_current_question;
      },
      getLoadingProgress(){
        return this.loading_progress;
       // return this.$store.getters.getTestValue;
      },
      getLoadingState(){
        return this.loading_state;
       // return this.$store.getters.getTestValue;
      }
    },
    
    methods:{
      goNext(currentQuestion){
        this.getQuestion(currentQuestion.id+1);
      },
      startTest(){
        console.log('startTest');
        this.test_result.created_at=Date.now();
        this.getQuestion(1);
      },
      getQuestion(question_id){
        this.$store.dispatch('loadNewQuestion',{question_id:question_id}).then((response) => { 
          console.log(response);
          this.my_answer=null;
          this.test_current_question=response.data.data;
      });
      },
      selectAnswer(answer){
        this.my_answer=answer.id;
        this.$store.dispatch('answerQuestion',{question:this.test_current_question,answer}).then((response) => { 
          console.log(response);
          this.test_current_question=response.data.data;
          if(this.my_answer==this.test_current_question.answer_id) this.my_ok++;
          else this.my_error++;
      });
      }


 
      
    }
  }
  </script>
  