//
import IndexPage from "./components/pages/IndexPage.vue";
import router from "./routers/router";
import { store, axiosInstance } from "./stores/store";
import { createApp } from "vue";

import { i18n } from "./configs/i18n";

import SysLogin from "./components/sys/auth/Login.vue";
import SysNotificationList from './components/sys/notification/NotificationList.vue';
import CountdownTimer from './components/elements/time/CountDown.vue';
import AuthByLink from "./components/sys/auth/AuthByLink.vue";

import GlobalSideMenu from "./components/sys/navi/GlobalSideMenu.vue";
//FOR TEST
//import HelloWorld from "./components/views/HelloWorld.vue";
//import axios from "axios";
//import interseptorSetup from "./configs/axios";
/* import { defineAsyncComponent } from 'vue'
const HelloWorldAsync = defineAsyncComponent(() =>
  import('./components/views/HelloWorld.vue')
) */
/* */
import FormsInputText from "./components/ui/forms/FormsInputText.vue";
import FormsTextArea from "./components/ui/forms/FormsTextArea.vue";
import FormsDate from "./components/ui/forms/FormsDate.vue";
import FormsDateTime from "./components/ui/forms/FormsDateTime.vue";
import FormsRadio from "./components/ui/forms/FormsRadio.vue";
import FormsSelect from "./components/ui/forms/FormsSelect.vue";
import FormsSwitch from './components/ui/forms/FormsSwitch.vue';
import FormsSwitchDigit from './components/ui/forms/FormsSwitchDigit.vue';
import StringArrayManage from "./components/elements/strings/StringArrayManage.vue";


import TestMain from "./components/modules/testing/TestMain.vue";

import UploadWidget from "./components/modules/files/UploadWidget.vue";
//const axiosInstance = axios.create({ withCredentials: true });
//interseptorSetup(axiosInstance);
const app = createApp(IndexPage)
    .use(store)
    .use(router)
    .use(i18n) //.use(axios) 
    .component("global-side-menu", GlobalSideMenu)
    .component("sys-login", SysLogin)
    .component("auth-by-link", AuthByLink)
    .component("ui-form-input-txt", FormsInputText)
    .component("ui-form-date", FormsDate)
    .component("ui-form-date-time", FormsDateTime)
    .component("ui-form-radio", FormsRadio)
    .component("ui-form-select", FormsSelect)
    .component("ui-form-txt-area", FormsTextArea)
    .component("ui-form-switch", FormsSwitch)
    .component("ui-form-switch-digit", FormsSwitchDigit)
    .component("file-upload-widget", UploadWidget)
    .component("string-array-manager", StringArrayManage)
    .component("sys-notification-list", SysNotificationList)
    .component("sys-countdown-timer", CountdownTimer)
    .component("test-main", TestMain)

    

    ;
/* import methods from './mixins/string_mixins';
    Vue.mixin({
      methods: methods
    }) */
    // this.$helpers.isValidEmail()
/* import helpers from './plugins/sring_helpers';
const plugin = {
  install () {
    Vue.prototype.$helpers = helpers
    Vue.helpers = helpers
  }
} 
app.use(plugin)*/
import stringHelpers from './plugins/string_plugin'; //$stringHelpers
app.use(stringHelpers); 


app.config.globalProperties.$axios = { ...axiosInstance };
app.mount("#app");
//require('./bootstrap');
//
/* window.Vue = require('vue'); */
//import Vue from 'vue'
//import router from './routers/router';
//import VueRouter from 'vue-router';
//import {store} from './stores/store';
//import {i18n} from './configs/i18n';
//import {eventBus} from './services/eventBus';
//Vue.prototype.$bus = eventBus;

/* Object.defineProperty(Vue.prototype,"$bus",{
	get: function() {
		return this.$root.bus;
	}
}); */

/* import App from './App.vue'
//Vue.component('app', require('./App.vue').default);
//Vue.component('vacancy-view', require('./components/Vacancy/VacancyComponent.vue').default);
//Vue.component('media-controll', require('./components/MediaContentShow/MediaControll').default);
//Vue.component('photo360', require('./components/passive/MediaContent/Photo360.vue').default);
 


 const app = new Vue({
  template:'<h1>dd</h1>',
    el: 'App',    
    test:'test',
    store: store,
    router: router,
   // i18n,
   components:
   {
       App
   },
   
});
console.log(app); */
