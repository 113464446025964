import { openDB } from 'idb';
const dbPromise = openDB('keyval-store', 1, {
  upgrade (db) {
    db.createObjectStore('keyval')
  }
});

export const idbKeyval = {
  async get (key) {
      return (await dbPromise).get('keyval', key)//.then((valFromDb)=>{console.log(valFromDb);return valFromDb})
      
  },
  async set (key, val) {
    //return await (await dbPromise).put({ id: key, data: val });
    return await (await dbPromise).put('keyval', val, key)//.then((valFromDb)=>{console.log(valFromDb);return valFromDb})
  },
  async delete (key) {
    return await (await dbPromise).delete('keyval', key)//.then((valFromDb)=>{console.log(valFromDb);return valFromDb})
  },
  async clear () {
    return await (await dbPromise).clear('keyval')//.then((valFromDb)=>{console.log(valFromDb);return valFromDb})
  },
  async keys () {
    return await  (await dbPromise).getAllKeys('keyval')//.then((valFromDb)=>{console.log(valFromDb);return valFromDb})
  }
};