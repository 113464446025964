import { createI18n } from "vue-i18n";
import axios from "axios";

export const i18n = createI18n({
    locale: "ru",
    fallbackLocale: "ru",
    messages: {
        ru: {
            controll:'управление',
            actions:'действия',
            visibility:'видимость',
            date:'дата',
            title:'название',
            err_connect:'ошибка соединения',
            team:'команда',
            create:'создать',
            login: "вход",
            events: "события",
            exit: "выход",
            navi: "навигация",
            loading_progress:'загрузка...',

            search_panel: {
                search: "Поиск",
                search_input_hint: "Введите серийный номер, мак или почту",
            },
        },
        en: {
            controll:'controll',
            actions:'actions',
            visibility:'visibility',
            date:'date',
            title:'title',
            team:'team',
            err_connect:'connection error',
            create:'create',
            login: "login",
            events: "events",
            exit: "exit",
            navi: "navigation",
            loading_progress:'loading...',

            search_panel: {
                search: "Search",
                search_input_hint: "Введите серийный номер, мак или почту",
            },
        },
    },
});

export const setI18nLanguage = (lang) => {
    i18n.locale = lang;
    axios.defaults.headers.common["Accept-Language"] = lang;
    document.querySelector("html").setAttribute("lang", lang);
    return lang;
};
