
/* eslint-disable */

import  {string_helpers}  from './string_helpers';    
 
//$stringHelpers.adaptDateTime()
const myPlugin = {
    install(app) {
        app.config.globalProperties.$stringHelpers = string_helpers;
        /* app.config.globalProperties.$numFormatWithDollar = (key) => {
            return key ? '$' + Number(key).toLocaleString() : '-';
        } */
    }
}

export default myPlugin;