<template>
  <div class="home">
   
    <p>{{ $t('test')    }} {{getTest}}</p>
    <input  @change="setTestValue($event)"  type="text">
    <button @click="setTestValue">TEST</button>
  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from '../views/HelloWorld.vue'
export default {
  name: 'HomeView',
  components: {
   // HelloWorld
  },
  computed:{
    getTest(){
      return this.$store.getters.getTestValue;
    }
  },
  methods:{
    setTestValue(e)
    {
      console.log(e.target.value);
      //this.$store.dispatch('removeUserRole',{user_id:1,role_id:4});
      this.$store.dispatch('setUserRole',{user_id:1,role_id:1});
      this.$store.dispatch('setUserRole',{user_id:1,role_id:2});
      this.$store.dispatch('setUserRole',{user_id:1,role_id:3});
      this.$store.dispatch('setUserRole',{user_id:1,role_id:4});
    },
  }
}
</script>
