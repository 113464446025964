<template>
    <div class="file-upload-widget">
        <div class="drop-zone" @dragenter.prevent @dragover.prevent @drop.prevent="onDrop">
            <p v-if="!getLoadingState">Перетащите файлы сюда</p>
            <p v-else>Загрузка... {{ getLoadingProgress }}% ожидайте.</p>
        </div>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
import api from '../../../configs/api';
 
  export default {
    name: 'files-upload-widget',
    components: {
     // HelloWorld
    },
    data:function(){
        return {
            loading_progress:0,
            loading_state:false,

        }
    },
    computed:{
      getLoadingProgress(){
        return this.loading_progress;
       // return this.$store.getters.getTestValue;
      },
      getLoadingState(){
        return this.loading_state;
       // return this.$store.getters.getTestValue;
      }
    },
    
    methods:{
        setUploadProgress(progressEvent){
            this.loading_progress=Math.round(progressEvent.progress*100);
            //if(progressEvent.progress==1) this.loading_state=false;
        console.log(progressEvent)
    },
      onDrop(e){
        this.loading_progress=0;
        this.loading_state=true;
        const files= [...e.dataTransfer.files];
        console.log(files);
        const url = api.files.upload;
        console.log(url);
        const formData = new FormData();
        files.forEach(file => {
            console.log(file);
            formData.append("files[]", file);
        });
           
        this.$axios.post(url, formData,{
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                onUploadProgress: progressEvent => this.setUploadProgress(progressEvent)
            }).then((response) => {
                this.loading_state=false;
                    console.log(response);
                 
                if (response.data.errCode === 0) {
                    console.log("Data ok!");
                    this.$emit('added',response.data.data.files);
                }
                response;
            });


      },
      setTestValue(e)
      {
        console.log(e.target.value);
        this.$store.commit('setTestValue',e.target.value);
      },
    }
  }
  </script>
  